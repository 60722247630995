import React from 'react';
import styled from 'styled-components';
import { APP_URL } from '../config.js';

const Container = styled.section`
  background-color: #ffffff;
  width: 50%;
`;

const UploadArea = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  padding: 1rem 0;
  flex: 1;
  text-align: center;
`;

const Field = `
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  min-height: 3rem;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  border-color: #dbdbdb;
  box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
  max-width: 100%;
  width: 100%;
  align-items: center;
`;

const FormField = styled.div`
  margin: 1.5rem 0;

  label {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    display: block;
  }
`;

const Input = styled.input`
  padding: 0.5rem;

  ${Field}
`;

const TextArea = styled.textarea`
  ${Field}
`;

const SubmitButton = styled.button`
  background-color: #001dfb;
  color: #ffffff;
  padding: 1.2rem;
  cursor: pointer;
  width: 20rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 0;
  box-shadow: none;
  margin: 4rem auto;
  display: block;
`;

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            mail: null,
            phone: '5xx',
            resume: null,
            coverLetter: null,
            uploadText: 'Upload File'
        };
    }

    nameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    mailChange = (event) => {
        this.setState({ mail: event.target.value });
    }

    phoneChange = (event) => {
        this.setState({ phone: event.target.value });
    }

    coverLetterChange = (event) => {
        this.setState({ coverLetter: event.target.value });
    }

    resumeChange = (event) => {
        this.setState({ resume: event.target.files[0] });
        this.setState({ uploadText: event.target.files[0].name });
    }

    uploadForm = () => {
        if (this.state.name !== null && this.state.email !== null && this.state.resume !== null) {
            const formData = new FormData();

            formData.append('name', this.state.name);
            formData.append('mail', this.state.mail);
            formData.append('phone', this.state.phone);
            if (this.state.coverLetter != null) {
                formData.append('coverLetter', this.state.coverLetter);
            }
            formData.append('resume', this.state.resume);

            alert('Thank you for your interest!');

            fetch(APP_URL, {
                method: 'POST',
                body: formData
            }).then((response) => console.log(response));

            this.setState({ uploadText: 'Upload File' });
        }
    }

    render = () => {
        return (
            <Container>
                <h2
                    css={`
                  font-weight: 600;
                  letter-spacing: 1.2;
                  text-align: center;
                `}
                >
                    APPLY NOW
              </h2>
                <form
                    netlify
                    css={`
                  padding: 2rem 4rem;
                `}
                >
                    <FormField>
                        <label htmlFor="name">Name</label>
                        <Input name="name" id="name" autoComplete="name" onChange={this.nameChange} required />
                    </FormField>
                    <FormField>
                        <label htmlFor="email">Email</label>
                        <Input name="email" id="email" type="email" autoComplete="email" onChange={this.mailChange} required />
                    </FormField>
                    <FormField>
                        <label htmlFor="phone">Telephone (Optional)</label>
                        <Input name="phone" id="phone" type="number" onChange={this.phoneChange} required />
                    </FormField>
                    <label htmlFor="resume">
                        <p>Resume</p>
                        <UploadArea>
                            <p>{this.state.uploadText}</p>
                        </UploadArea>
                        <input
                            type="file"
                            accept=".pdf,.png,.jpg"
                            onChange={this.resumeChange}
                            id="resume"
                            css={`
                                visibility: hidden;
                                height: 0;
                                overflow: hidden;
                                `}
                        />
                    </label>
                    <FormField>
                        <label htmlFor="cover-letter">Cover Letter (Optional)</label>
                        <TextArea name="cover-letter" rows="8" onChange={this.coverLetterChange} />
                    </FormField>
                    <SubmitButton type="button" onClick={this.uploadForm}>Apply</SubmitButton>
                </form>
            </Container>
        );
    }
}

export default Form;
